import { getUserDt, newEdgeId, primitivesAndMetaCommon } from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import {
  DataTypeEdgeIdAndDirection,
  DATA_TYPE_EDGE_DIRECTION,
} from "sutro-common/edges/dt-edge-id-and-direction";
import { EDGE_QUANTITY_TYPES } from "sutro-common/edges/data-type-edge";
import { PluginKey } from "sutro-common/plugins/plugin-keys";
import { TEXT_LENGTH } from "sutro-common/primitive-text";

import { StudioDefinitionPlugin } from "../types.js";
import { doesDefinitionIncludeReceivingPayments } from "./does-definition-include-marketplace-payments.js";
export const updateDefinitionForReceivingPayments: StudioDefinitionPlugin = ({
  draftDefinition: { dataTypes, actions },
  changeTracker,
}) => {
  const shouldSetUpMarketplacePayments = doesDefinitionIncludeReceivingPayments(
    { actions }
  );

  if (shouldSetUpMarketplacePayments) {
    const userDt = getUserDt(dataTypes);
    const definitionIncludesUserStripeExpressAccountIdEdge = userDt.edges.some(
      (edge) => edge.pluginData?.isStripeExpressAccountIdEdge
    );
    if (definitionIncludesUserStripeExpressAccountIdEdge === false) {
      /** @todo:  use some kind of constructor function to create edges and models like this */
      const dtEdgeToStripeExpressAccountId: DataTypeEdge = {
        direction: DATA_TYPE_EDGE_DIRECTION.has,
        quantity: EDGE_QUANTITY_TYPES.SINGLE,
        fieldName: "Stripe Express Account ID",
        relatedDtId: primitivesAndMetaCommon.TEXT.id,
        textLength: TEXT_LENGTH.medium,
        edgeId: newEdgeId(),
        /** @todo:  these should be scoped per plugin, but that requires more careful design so do this for now */
        pluginData: {
          isStripeExpressAccountIdEdge: true,
          [PluginKey.PRIVATE_FIELDS]: {
            internalField: true,
          },
        },
      };
      userDt.edges.push(dtEdgeToStripeExpressAccountId);
      changeTracker.trackChange("Added Stripe Express Account ID to User");
    }

    const definitionIncludesUserStripePayoutsEnabledEdge = userDt.edges.some(
      (edge) => edge.pluginData?.isStripePayoutsEnabledEdge
    );
    if (definitionIncludesUserStripePayoutsEnabledEdge === false) {
      const dtEdgeToStripePayoutsEnabled: DataTypeEdge = {
        direction: DATA_TYPE_EDGE_DIRECTION.has,
        quantity: EDGE_QUANTITY_TYPES.SINGLE,
        // Note: this is linked to a magic string below, so be careful if we change it
        fieldName: "Stripe Payouts Enabled",
        relatedDtId: primitivesAndMetaCommon.BOOLEAN.id,
        edgeId: newEdgeId(),
        pluginData: {
          isStripePayoutsEnabledEdge: true,
          [PluginKey.PRIVATE_FIELDS]: {
            internalField: true,
          },
        },
      };
      userDt.edges.push(dtEdgeToStripePayoutsEnabled);
      changeTracker.trackChange("Added Stripe Payouts Enabled to User");
    }
  }
};
