import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { changeTextLength } from "@sutro/studio2-quarantine/definitions/definition-updaters/change-text-length";
import { Draft, produce } from "immer";
import {
  Definition,
  getDt,
  getUserDt,
  isDtActionInput,
  isDtAppRoot,
  isDtContainer,
  isDtIdentity,
  isDtUnion,
  longTextLowercaseFieldNames,
  TEXT_DT_ID,
} from "sutro-common";
import {
  DataTypeEdgeIdAndDirection,
  DATA_TYPE_EDGE_DIRECTION,
} from "sutro-common/edges/dt-edge-id-and-direction";
import { getEdgeIdAndDirectionForEdge } from "sutro-common/edges/get-edge-id-and-direction-for-edge";
import { TEXT_LENGTH } from "sutro-common/primitive-text";

import { getDraftEdge } from "~/lib/definitions/definition-updaters";
import { removeOrphanedUnionDts } from "~/lib/definitions/definition-validation/remove-orphaned-union-dts";
import { updateDtsToEnsureAllHasEdgesHaveValidCorrespondingEdges } from "~/lib/definitions/definition-validation/update-dts-to-ensure-all-has-edges-have-valid-corresponding-edges";

import { adjustOtherAclsBasedOnWhoCanAddRemove } from "./adjust-other-acls-based-on-who-can-add-remove";
import { ensureAclsAreSetCorrectly } from "./ensure-acls-are-set-correctly";
import { ensureDtCorrespondingBelongsToEdgeQuantityIsCorrect } from "./ensure-dt-corresponding-belongs-to-edge-quantity-is-correct";
import { ensureGeneratedEdgeNameIsCorrect } from "./ensure-generated-edge-name-is-correct";
import { ensureIfYouHaveSomeUsersTheyBelongToSomeOfYouExceptAppRoot } from "./ensure-if-you-have-some-users-they-belong-to-some-of-you-except-app-root";
import { ensureModifierCanAddRemoveIsSetCorrectlyForUser } from "./ensure-modifier-can-add-remove-is-set-correctly-for-user";
import { ensureSomeEdgeBelongsQuantityIsRight } from "./ensure-some-edge-belongs-quantity-is-right";
import { ensureSomeEdgesHaveRequiredAnnotations } from "./ensure-some-edges-have-required-annotations";
import { ensureUserHasSomeDtAndDtBelongsToUserIfNecessary } from "./ensure-user-has-some-dt-and-dt-belongs-to-user-if-necessary";
import { markDTsOrEdgesAsIncomplete } from "./mark-dts-or-edges-as-incomplete";
import { removeDtsThatDontRelateToAContainerOrUnion } from "./remove-dts-that-dont-relate-to-a-container-or-union";
import { removeEdgesThatPointToDtThatDoesntExist } from "./remove-edges-that-point-to-dt-that-doesnt-exist";
import { removeOrphanedIdentityDts } from "./remove-orphaned-identity-dts";
import { setLongTextFieldsTextLength } from "./set-long-text-fields-text-length";
import { updateDtsToEnsureAllContainersHaveAnEdgeFromAUser } from "./update-dts-to-ensure-all-containers-have-an-edge-from-a-user";
import { updateDtsToEnsureEdgesAreMarkedAsCreatorEdgeAppropriately } from "./update-dts-to-ensure-edges-are-marked-as-creator-edge-appropriately";

/** @todo:  take in lastUserAction and ensure fix doesn't undo user action */
/** @todo:  prevent anything from belongsTo multiple non-user models (in the future this will work via a union relationship, but doesn't today) */
/** @todo:  check we don't have any paths to user roles that don't exist any more (set these to -1 if so) */
/** @todo:  validate two DTs don't have the same name, and one DT doesn't reuse the same fieldName */

export const getUpdatedDefinitionFollowingInvariantChecksAndFixes = ({
  definition,
  changeTracker,
}: {
  definition: Definition;
  changeTracker: ChangeTracker;
}) => {
  const errorMessage: string | undefined = undefined;

  const updatedDefinition = produce(definition, (draftDefinition) => {
    const draftDataTypes = draftDefinition.dataTypes;
    const draftActions = draftDefinition.actions;

    do {
      if (changeTracker.hasFailed()) {
        changeTracker.showChanges("VALIDATION FAILED");
        throw new Error(changeTracker.getFailureReason());
      }
      changeTracker.newCycle();

      markDTsOrEdgesAsIncomplete(draftDataTypes);

      removeEdgesThatPointToDtThatDoesntExist({
        draftDataTypes,
        changeTracker,
      });

      updateDtsToEnsureAllHasEdgesHaveValidCorrespondingEdges({
        draftDataTypes,
        changeTracker,
      });

      updateDtsToEnsureEdgesAreMarkedAsCreatorEdgeAppropriately({
        draftDataTypes,
        changeTracker,
      });

      updateDtsToEnsureAllContainersHaveAnEdgeFromAUser({
        draftDataTypes,
        changeTracker,
      });

      removeOrphanedIdentityDts({ draftDataTypes, changeTracker });
      removeOrphanedUnionDts({ draftDataTypes, changeTracker });

      removeDtsThatDontRelateToAContainerOrUnion({
        draftDataTypes,
        actions: draftActions,
        changeTracker,
      });

      if (changeTracker.getChangesForCycle().length > 0) {
        continue;
      }

      /** @todo:  this logic probably doesn't make the most sense now we have unified belongsTo and has edges */
      for (const dt of draftDataTypes.filter(isDtContainer)) {
        ensureSomeEdgesHaveRequiredAnnotations(dt, changeTracker);

        ensureAclsAreSetCorrectly({
          definition,
          dataType: dt,
          draftDataTypes,
          changeTracker,
        });

        setLongTextFieldsTextLength(dt);

        if (dt.isIncomplete !== true && isDtAppRoot(dt) === false) {
          for (const edge of dt.edges) {
            if (
              edge.direction === DATA_TYPE_EDGE_DIRECTION.has &&
              // We only check that the corresponding DT belongsTo us if we're not in progress, because if we don't not yet have a relatedDtId obviously this would break
              edge.isIncomplete !== true
            ) {
              const containedDt = getDt(draftDataTypes, edge.relatedDtId);

              // Don't need to run these checks on unions or identity types.
              /** @todo:  maybe this should be enabled for them actually? */
              if (
                isDtUnion(containedDt) ||
                isDtIdentity(containedDt) ||
                isDtActionInput(dt)
              ) {
                continue;
              }

              const correspondingBelongsToEdge = getDraftEdge({
                edgeIdAndDirection: {
                  edgeId: edge.edgeId,
                  direction: DATA_TYPE_EDGE_DIRECTION.belongsTo,
                },
                draftDt: containedDt,
              });

              ensureSomeEdgeBelongsQuantityIsRight({
                edge,
                dataType: dt,
                containedDt,
                correspondingBelongsToEdge,
                changeTracker,
              });
              // Likewise and conversely
              // if (
              //   hasisEdgeSomeEdge(edge) &&
              //   hasEdge.modifierCanAddRemove?.type === MODIFIER_CAN_ADD_REMOVE_TYPES.OWN_ONLY &&
              //   correspondingBelongsToEdge.edgeBelongsQuantity ===
              //     EDGE_QUANTITY_TYPES.SOME &&
              //   /** @todo:  document why exactly */
              //   isDtUser(containedDt) !== true
              // ) {
              //   // const debug = JSON.parse(
              //   //   JSON.stringify({
              //   //     hasEdge,
              //   //     correspondingBelongsToEdge,
              //   //     containedDt,
              //   //   })
              //   // );
              //   // debugger;
              //   correspondingBelongsToEdge.edgeBelongsQuantity =
              //     EDGE_QUANTITY_TYPES.SINGLE;debugger
              //   changeLog.log(
              //     `Set ${containedDt.name} belongsTo ${dt.name} to EDGE_QUANTITY_TYPES.SINGLE because ${dt.name} has EDGE_QUANTITY_TYPES.SOME ${containedDt.name} and they are only things belonging to owner`
              //   );
              // }
              ensureDtCorrespondingBelongsToEdgeQuantityIsCorrect({
                edge,
                dataType: dt,
                containedDt,
                correspondingBelongsToEdge,
                changeTracker,
              });

              ensureIfYouHaveSomeUsersTheyBelongToSomeOfYouExceptAppRoot({
                edge,
                dataType: dt,
                containedDt,
                correspondingBelongsToEdge,
                changeTracker,
              });

              ensureUserHasSomeDtAndDtBelongsToUserIfNecessary({
                edge,
                containedDt,
                dataType: dt,
                draftDataTypes,
                changeTracker,
              });

              adjustOtherAclsBasedOnWhoCanAddRemove({
                edge,
                containedDt,
                dataType: dt,
                correspondingBelongsToEdge,
                changeTracker,
              });
            }

            ensureGeneratedEdgeNameIsCorrect({
              changeTracker,
              dataType: dt,
              draftDataTypes,
              edge,
            });
          }
        }
      }

      const userDt = getUserDt(draftDataTypes);

      ensureModifierCanAddRemoveIsSetCorrectlyForUser(userDt, changeTracker);

      ensureTextLengthIsLongForCertainFields({
        draftDefinition,
      });
    } while (changeTracker.getChangesForCycle().length > 0);
  });

  return {
    success: true,
    errorMessage,
    dataTypesWithChangesAndIncompleteAnnotated: updatedDefinition.dataTypes,
  };
};

const ensureTextLengthIsLongForCertainFields = ({
  draftDefinition,
}: {
  draftDefinition: Draft<Definition>;
}) => {
  for (const draftDt of draftDefinition.dataTypes.filter(isDtContainer)) {
    for (const edge of draftDt.edges) {
      if (
        longTextLowercaseFieldNames.includes(edge.fieldName.toLowerCase()) &&
        edge.relatedDtId === TEXT_DT_ID
      ) {
        changeTextLength(draftDefinition, {
          edgeIdAndDirection: getEdgeIdAndDirectionForEdge(edge),
          newTextLength: TEXT_LENGTH.long,
        });
      }
    }
  }
};
